

import {Component, Mixins, Prop, Vue, Watch} from "vue-property-decorator";

@Component({})
export default class ObjectInputMixin extends Vue {
    @Prop() value:any;
    @Prop() item:any;

    localValue = Object.assign(
        {},
        this.item ? this.item : {},
        this.value ? this.value : {},
    );

    @Watch('value')
    onValueChange() {
        this.localValue = Object.assign({}, this.value ? this.value : {});
        console.log("mix: ",this.localValue);
    }

    @Watch('item')
    onItemChange() {
        this.localValue = Object.assign({}, this.item ? this.item : {});
    }



    input() {
        console.log("input called, shall emit ", this.localValue);
        this.$emit('input', this.localValue);
        this.$emit('value', this.localValue);
    }

}
